@font-face {
    font-family: 'NotoSans';
    font-style: italic;
    font-weight: 100;
    font-display: swap;
    src: url(./notosans/NotoSans-Italic-VariableFont_wdth\,wght.ttf) format('truetype');
  }
  @font-face {
    font-family: 'NotoSans';
    font-style: italic;
    font-weight: 200;
    font-display: swap;
    src: url(./notosans/NotoSans-Italic-VariableFont_wdth\,wght.ttf) format('truetype');
  }
  @font-face {
    font-family: 'NotoSans';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url(./notosans/NotoSans-Italic-VariableFont_wdth\,wght.ttf) format('truetype');
  }
  @font-face {
    font-family: 'NotoSans';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url(./notosans/NotoSans-Italic-VariableFont_wdth\,wght.ttf) format('truetype');
  }
  @font-face {
    font-family: 'NotoSans';
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: url(./notosans/NotoSans-Italic-VariableFont_wdth\,wght.ttf) format('truetype');
  }
  @font-face {
    font-family: 'NotoSans';
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: url(./notosans/NotoSans-Italic-VariableFont_wdth\,wght.ttf) format('truetype');
  }
  @font-face {
    font-family: 'NotoSans';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url(./notosans/NotoSans-Italic-VariableFont_wdth\,wght.ttf) format('truetype');
  }
  @font-face {
    font-family: 'NotoSans';
    font-style: italic;
    font-weight: 800;
    font-display: swap;
    src: url(./notosans/NotoSans-Italic-VariableFont_wdth\,wght.ttf) format('truetype');
  }
  @font-face {
    font-family: 'NotoSans';
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: url(./notosans/NotoSans-Italic-VariableFont_wdth\,wght.ttf) format('truetype');
  }
  @font-face {
    font-family: 'NotoSans';
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url(./notosans/NotoSans-VariableFont_wdth,wght.ttf) format('truetype');
  }
  @font-face {
    font-family: 'NotoSans';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url(./notosans/NotoSans-VariableFont_wdth,wght.ttf) format('truetype');
  }
  @font-face {
    font-family: 'NotoSans';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(./notosans/NotoSans-VariableFont_wdth,wght.ttf) format('truetype');
  }
  @font-face {
    font-family: 'NotoSans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(./notosans/NotoSans-VariableFont_wdth,wght.ttf) format('truetype');
  }
  @font-face {
    font-family: 'NotoSans';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(./notosans/NotoSans-VariableFont_wdth,wght.ttf) format('truetype');
  }
  @font-face {
    font-family: 'NotoSans';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(./notosans/NotoSans-VariableFont_wdth,wght.ttf) format('truetype');
  }
  @font-face {
    font-family: 'NotoSans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(./notosans/NotoSans-VariableFont_wdth,wght.ttf) format('truetype');
  }
  @font-face {
    font-family: 'NotoSans';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url(./notosans/NotoSans-VariableFont_wdth,wght.ttf) format('truetype');
  }
  @font-face {
    font-family: 'NotoSans';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url(./notosans/NotoSans-VariableFont_wdth,wght.ttf) format('truetype');
  }