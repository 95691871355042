// Override default variables before the import
// you can open the bootstrap.scss module and check any available variables.
$primary: #0e2774;
$secondary: #0067a0;
$success: #027a48;
$info: #00d8ff;
$warning: #ffc107;
$danger: #b42318;
$light: #fbfffe;
$dark: #25194f;

//custom color to be mapped
$accent : #0e2774;
$accent-table-primary : #a5c9dc;
$accent-table-secondary : #d6f3ff;

$theme-colors: (
  primary: $primary,
  secondary: $secondary,
  success: $success,
  info: $info,
  warning: $warning,
  danger: $danger,
  light: $light,
  dark: $dark,
// add any additional color below
  accent: $accent,
  accent-table-primary: $accent-table-primary,
  accent-table-secondary: $accent-table-secondary
// now bg-accent,btn-accent,etc.. can be called
);

$font-family-sans-serif: "NotoSans", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !default;
$font-family-serif:      "Charis SIL", Georgia, "Times New Roman", Times, serif !default;
$font-family-monospace:  Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base:       $font-family-sans-serif !default;

// Set the default font color to the dark theme color
$body-color: $dark !default;

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

.custom-tooltip {
  --bs-tooltip-bg: var(--bs-primary);
  --bs-tooltip-color: var(--bs-white);
  --bs-tooltip-opacity: 1;
  --bs-tooltip-max-width: 450px;
}

.form-control,
.form-select,
.input-group-text,
.table,
.table-secondary {
  --bs-emphasis-color: var(--bs-dark);
  --bs-border-color: var(--bs-gray-500);
  --bs-table-border-color: var(--bs-gray-500); 
}

.table-primary {
  --bs-table-bg: var(--bs-accent-table-primary);
}
.table-secondary {
  --bs-table-bg: var(--bs-accent-table-secondary);
}

// .tooltip-inner {
//   text-align: left;
// }