
.simulation-results-table tr th:nth-child(2) {
	border-right: 1px solid #000;
	border-left: 1px solid #000;
	background-color: #ffd;
}

.simulation-results-table tr:first-of-type th:nth-child(2) {
	border-top: 1px solid #000;
}

.simulation-results-table tr td:nth-child(2) {
	border-right: 1px solid #000;
	border-left: 1px solid #000;
	background-color: #ffd;
}

.simulation-results-table tr:last-of-type td:nth-child(2) {
    border-bottom: 1px solid #000;
}

.simulation-results-table-benchmark tr th:nth-child(3) {
	border-right: 1px solid #000;
	border-left: 1px solid #000;
	background-color: #ffd;
}

.simulation-results-table-benchmark tr:first-of-type th:nth-child(3) {
	border-top: 1px solid #000;
}

.simulation-results-table-benchmark tr td:nth-child(3) {
	border-right: 1px solid #000;
	border-left: 1px solid #000;
	background-color: #ffd;
}

.simulation-results-table-benchmark tr:last-of-type td:nth-child(3) {
    border-bottom: 1px solid #000;
}


.js-plotly-plot {
    width: 100vw;
    height: 100vh;
}


@media print {      
    body {
        width: 1100px !important;
    }
    
    .bg-light {
        background-color: #fff !important;
        border-radius: 0 !important;
        box-shadow: none !important;
    }

    .form-select {
        margin: 0 !important;
        padding: 0 !important;
		border: none !important;
    }
    
    /* Hide navbar and footer in print view */
    .navbar, .footer {
        display: none !important;
    }
}
