body {
    min-height: 100vh;
    min-height: -webkit-fill-available;
    background-image: url(./assets/background_page_sm.png);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: 100% 100%;
}

html {
    min-height: 100vh;
    height: -webkit-fill-available;
}

/* main {
    min-height: 96vh;
    height: -webkit-fill-available;
} */

/*
* dashboard example inline style
*/

.bd-placeholder-img {
    font-size: 1.125rem;
    text-anchor: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

@media (min-width: 768px) {
    .bd-placeholder-img-lg {
        font-size: 3.5rem;
    }
}

.b-example-divider {
    width: 100%;
    height: 3rem;
    background-color: rgba(0, 0, 0, .1);
    border: solid rgba(0, 0, 0, .15);
    border-width: 1px 0;
    box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
}

.b-example-vr {
    flex-shrink: 0;
    width: 1.5rem;
    height: 100vh;
}

.bi {
    vertical-align: -.125em;
    fill: currentColor;
}

.bg-light {
    border-radius: 10px;
    box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
}

.nav-scroller {
    position: relative;
    z-index: 2;
    height: 2.75rem;
    overflow-y: hidden;
}

.nav-scroller .nav {
    display: flex;
    flex-wrap: nowrap;
    padding-bottom: 1rem;
    margin-top: -1px;
    overflow-x: auto;
    text-align: center;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
}

.btn-bd-primary {
    --bd-violet-bg: #712cf9;
    --bd-violet-rgb: 112.520718, 44.062154, 249.437846;

    --bs-btn-font-weight: 600;
    --bs-btn-color: var(--bs-white);
    --bs-btn-bg: var(--bd-violet-bg);
    --bs-btn-border-color: var(--bd-violet-bg);
    --bs-btn-hover-color: var(--bs-white);
    --bs-btn-hover-bg: #6528e0;
    --bs-btn-hover-border-color: #6528e0;
    --bs-btn-focus-shadow-rgb: var(--bd-violet-rgb);
    --bs-btn-active-color: var(--bs-btn-hover-color);
    --bs-btn-active-bg: #5a23c8;
    --bs-btn-active-border-color: #5a23c8;
}

.bd-mode-toggle {
    z-index: 1500;
}

.bd-mode-toggle .dropdown-menu .active .bi {
    display: block !important;
}

/*
* dashboard.css
*/

.bi {
    display: inline-block;
    width: 1rem;
    height: 1rem;
}

/*
   * Sidebar
   */

@media (min-width: 768px) {
    .sidebar .offcanvas-lg {
        position: -webkit-sticky;
        position: sticky;
        top: 48px;
    }

    .navbar-search {
        display: block;
    }
}

.sidebar .nav-link {
    font-size: 1.1rem;
    font-weight: 500;
    color: #fbfffe;
}

.sidebar .nav-link :hover {
    color: white;
}

.nav {
    color: #fbfffe;
}

.nav:hover {
    color: white;
}

/* .sidebar .nav-link.active {
    color: --bs-secondary;
} */

.sidebar-heading {
    font-size: .75rem;
}

/*
* Navbar
*/

/* .navbar {
    min-height: 2vh;
} */

.navbar-brand {
    padding-top: .75rem;
    padding-bottom: .75rem;
    background-color: rgba(0, 0, 0, .25);
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .25);
}

.navbar .form-control {
    padding: .75rem 1rem;
}

.navbar-logo {
    max-width: 100%;
    max-height: 5vh;
}

.navbar-full-view {
    min-height: 100vh;
}

@media (max-width: 767.98px) {
    .navbar-full-view {
        min-height: auto;
    }
}

.scrollable-menu {
    height: 60vh;
    overflow-x: hidden;
    overflow-y: scroll;
}
  